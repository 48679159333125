import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignJustify, faBagShopping, faCaretDown, faMagnifyingGlass, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import {
    useNavigate,
    Link,
  } from "react-router-dom";
import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import {toast} from 'react-toastify'
import { useSelector, useDispatch } from "react-redux";
import convertNumber from '../../helpers/convertnumber'
import logo from './logo.png'

function Header() {

    let navigate = useNavigate()

    const refModal = useRef(null)

    const dispatch = useDispatch()
    const modalSideBar = useSelector(state => state.modalSideBar);
    const cart = useSelector(state => state.cart);

    const [totalPriceCart, setTotalPriceCart] = useState(0)
    const [totalProducts, setTotalProducts] = useState(0)
    const [inputSearch, setInputSearch] = useState('')
    const [openInputSearch, setOpenInputSearch] = useState(false)
    const [navigations, setNavigations] = useState([])

    const [showHiddenMenu, setShowHiddenMenu] = useState({
        shop: false,
        bag: false,
        apparel: false,
    })


    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)
        getCategory()
        getDataEcommerce()
        getNavigation()
        let cart = JSON.parse(localStorage.getItem('cart'))
        if(cart){
            dispatch({
                type: 'UPDATE_CART',
                data: cart
            })
        }
    },[])
    
    useEffect(() => {
        let priceResult = 0
        let amountResult = 0
        console.log(cart,'cart')
        cart.map(item => {
            priceResult += (item.product.discount ? item.product.discount : item.product.price) * item.quantity
            amountResult += item.quantity
        })
        setTotalPriceCart(priceResult)
        setTotalProducts(amountResult)
    },[cart])

    function getNavigation(){
        axios.get('/navigation')
        .then(({data}) => {
            setNavigations(data)
        })
        .catch(err => {
            toast.error('error get navigation. Please contact evernext team')
        })
    }

    function getDataEcommerce(){
        axios.get('/ecommerce')
        .then(({data}) => {
            localStorage.setItem('marketplaceInfo', JSON.stringify({_id: data._id, name: data.name}))
        })
        .catch(err => {
            toast.error('error get authentication. Please contact evernext team')
        })
    }

    const handleClick = (e) => {
        if(refModal.current && !refModal.current.contains(e.target)){
            dispatch({
                type: 'SET_OPEN_CLOSE_SIDEBAR',
                data: false
            })
        }
    }

    const handleClickMenu = (value) => {
        setShowHiddenMenu({...showHiddenMenu, [value]: !showHiddenMenu[value]})
    }
    const handleShowModalSidebar = () => {
        dispatch({
            type: 'SET_OPEN_CLOSE_SIDEBAR',
            data: true
        })
    }

    function getCategory(){
        axios.get('/category')
        .then(({data}) => {
            dispatch({
                type: 'SET_OPEN_CATEGORY_LIST',
                data: data
            }) 
        })
        .catch(err => {
            toast.error('error get category. Please contact evernext team')
        })
    }

    function handleSubmitSearch() {
        setInputSearch('')
        setOpenInputSearch(false)
        navigate(`/search/${inputSearch}`)
    }

    return (
            <div className="flex justify-between items-center absolute top-0 w-full z-10 bg-slate-900 p-5 md:px-10 lg:px-14 lg:pr-16 lg:py-5">
                <div className="flex items-center flex-1">
                    <div onClick={handleShowModalSidebar} className="border-[1px] text-white border-white border-solid h-5 p-1 rounded-[4px] flex items-center cursor-pointer">
                        <FontAwesomeIcon icon={faAlignJustify} className="text-xs"/>
                        <p className="text-[10px] hidden md:block ml-1">MENU</p>
                    </div>
                    {
                        openInputSearch ? 
                            <FontAwesomeIcon onClick={() => setOpenInputSearch(false)} icon={faCircleXmark} className={`text-zinc-200 ml-2 md:hidden`}/>
                        :
                            <FontAwesomeIcon onClick={() => setOpenInputSearch(true)} icon={faMagnifyingGlass} className={`text-zinc-200 ml-2 md:hidden`}/>
                    }
                    <input
                        className={`rounded-md p-1 h-5 text-xs ml-2 outline-none ${openInputSearch ? 'block' : 'hidden' }  md:block`}
                        value={inputSearch}
                        placeholder="search product"
                        onChange={(e) => setInputSearch(e.target.value)}
                        onKeyDown={(e) => e.key == 'Enter' && handleSubmitSearch()}
                    />
                </div>
                <div className={`flex-1 flex justify-center ${!openInputSearch ? 'block' : 'hidden' }`}>
                    <img 
                        src={logo}
                        className="max-h-16  cursor-pointer"
                        onClick={() => navigate('/')}
                    />
                </div>
                <div className="flex justify-end items-center text-zinc-200 cursor-pointer flex-1" onClick={() => navigate('/checkout')}>
                    <p className="text-[10px] mr-2 hidden md:block">CART / RP{convertNumber(totalPriceCart)}</p>
                    <p className='relative'>
                        <FontAwesomeIcon icon={faBagShopping} className="text-xs"/>
                        <p className={`text-yellow-400 text-xs absolute top-0 left-4`}>{totalProducts}</p>
                    </p>
                </div>
                {
                    modalSideBar && (
                        <div className="fixed w-full h-full z-50 bg-[#bebebe7d] top-0 left-0">
                            <div ref={refModal} className="h-full w-full max-w-fit py-10 px-4 bg-slate-900 text-white text-xs uppercase" style={{overflowY: 'auto'}}>
                                {/* <div className="w-52 my-8 border-b-[1px] pb-4 cursor-pointer">
                                    <div onClick={() => handleClickMenu('shop')} className="flex justify-between">
                                        <p className="font-semibold">SHOP</p>
                                        <FontAwesomeIcon icon={faCaretDown}/>
                                    </div>
                                    {
                                        showHiddenMenu.shop && <div className="px-4 mt-5">
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/tag/sale">Sale</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/tag/newest">New Arrival</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/tag/best seller">Best Seller</Link>
                                            </p>
                                        </div>
                                    }
                                </div> */}
                                {
                                    navigations.map(nav => {
                                        if(nav.title){
                                            return (
                                                <div className="w-52 my-8 border-b-[1px] pb-4 cursor-pointer">
                                                    {
                                                        nav.sub.length ? (
                                                            <>
                                                                <div onClick={() => handleClickMenu('bag')} className="flex justify-between">
                                                                    <p className="font-semibold">{nav.title}</p>
                                                                    <FontAwesomeIcon icon={faCaretDown} />
                                                                </div>
                                                                {
                                                                    showHiddenMenu.bag && <div className="px-4 mt-5">
                                                                        {
                                                                            nav.sub.map(sub => {
                                                                                return (
                                                                                    <p className="font-light mt-5">
                                                                                        <Link className="font-light mt-5" to={`/category/${sub}`}>{sub}</Link>
                                                                                    </p>
    
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="w-52 mt-8 cursor-pointer">
                                                                <div className="flex justify-between">
                                                                    <p className="font-semibold">
                                                                        <Link to={`/category/${nav.title}`}>{nav.title}</Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {/* <div className="w-52 my-8 border-b-[1px] pb-4 cursor-pointer">
                                    <div onClick={() => handleClickMenu('bag')} className="flex justify-between">
                                        <p className="font-semibold">Bag</p>
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </div>
                                    {
                                        showHiddenMenu.bag && <div className="px-4 mt-5">
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/waist bag">Waist Bag</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/backpack">Backpack</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/tas laptop">Tas Laptop</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/Travel Bag">Travel Bag</Link>
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div className="w-52 my-8 border-b-[1px] pb-4 cursor-pointer">
                                    <div className="flex justify-between">
                                        <p className="font-semibold">
                                            <Link to="/category/Accessories">Accessories</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="w-52 my-8 border-b-[1px] pb-4 cursor-pointer">
                                    <div onClick={() => handleClickMenu('apparel')} className="flex justify-between">
                                        <p className="font-semibold">Apparel</p>
                                        <FontAwesomeIcon icon={faCaretDown} />
                                    </div>
                                    {
                                        showHiddenMenu.apparel && <div className="px-4 mt-5">
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/Jacket">Jacket</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/T-shirt">T-shirt</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/Slippers">Slippers</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/Pants">Pants</Link>
                                            </p>
                                            <p className="font-light mt-5">
                                                <Link className="font-light mt-5" to="/category/Kemeja Flannel">Kemeja Flannel</Link>
                                            </p>
                                        </div>
                                    }
                                </div> */}
                            </div>
                        </div>
                    )
                }
            </div>
    )
}

export default Header;