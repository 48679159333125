const initialState = {
    isAuth: false,
    name: '',
    role: '',
    userId: '',
    cart: [],
    isOpenCart: false,
    categoryList: [false],
    isOpenSorting: false,
    modalSideBar: false,
    transaction: {
        cart: [],
        address: {
            city: '',
        },
        totalPrice: 0
    },
    person: null,
    isLogin: false,
    transactionStatus: null,
    productList: [],
    isSortProduct: null
};

export default (state = initialState, action) => {
    switch (action.type){
        case "SET_OPEN_CLOSE_CART":
            return {
                ...state,
                isOpenCart: action.data
            }
        case "SET_OPEN_CLOSE_SIDEBAR":
            return {
                ...state,
                modalSideBar: action.data
            }
        case "SET_OPEN_CATEGORY_LIST":
            return {
                ...state,
                categoryList: action.data
            }
        case "SET_OPEN_CLOSE_SORTING":
            return {
                ...state,
                isOpenSorting: action.data
            }
        case "ADD_PRODUCT_TO_CART":
            return {
                ...state,
                cart: action.data
            }
        case "UPDATE_CART":
            return {
                ...state,
                cart: action.data
            }
        case "ADD_TRANSACTION":
            return {
                ...state,
                transaction: action.data
            }
        case "UPDATE_TRANSACTION":
            return {
                ...state,
                transaction: action.data
            }
        case "UPDATE_TRANSACTION_STATUS":
            return {
                ...state,
                transactionStatus: action.data
            }
        case "UPDATE_PERSON":
            return {
                ...state,
                person: action.data
            }
        case "UPDATE_ISLOGIN":
            return {
                ...state,
                isLogin: action.data
            }
        case "UPDATE_SORT_PRODUCT":
            return {
                ...state,
                isSortProduct: action.data
            }
        default:
            return state;
    }
}