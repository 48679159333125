import {useEffect} from 'react'
import {
    useParams,
    useNavigate
  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'  
import convertnumber from '../../helpers/convertnumber';

function Checkout() {

    const navigate = useNavigate()
    const transactionStatus = useSelector(state => state.transactionStatus);

    useEffect(() => {
        if(!transactionStatus){
            navigate('/')
        }
    },[])

    return (
        <div className="lg:mt-[100px] md:mt-[96px] mt-[79px] md:px-10 lg:px-52 px-5 pb-8 max-w-5xl m-auto">
            <h3 className="text-center text-2xl">ORDER COMPLETE</h3>
            <div className="pt-1 flex justify-between">
                {
                    transactionStatus && (
                        <div className="p-5 bg-gray-100 flex-1 ml-2">
                            <p className="text-xl tetx-[#4caf50]">Thank you. Your order has been received.</p>
                            <div>
                                <p><b>No. Invoice:</b> {transactionStatus.transactionId} </p>
                                <p><b>Date:</b> {moment(transactionStatus.time).format('MMMM Do YYYY, h:mm a')}</p>
                                <p><b>Total:</b> Rp {convertnumber(transactionStatus.totalPrice)}</p>
                                <p><b>Payment method:</b> {transactionStatus.paymentMethod} - ({transactionStatus.paymentNumber})</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
export default Checkout