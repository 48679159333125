

function Footer() {
    return (
        <div className="flex justify-center items-center w-full z-10 bg-gray-800 text-gray-300 p-5 md:px-52 md:py-3">
            <p className="text-xs">&copy; 2022 Created by EVERNEXT</p>
        </div>
    )
}

export default Footer;