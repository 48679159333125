import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import {toast} from 'react-toastify'
import convertNumber from '../../helpers/convertnumber'
import axios from 'axios'
import {
    useNavigate,
    Link,
  } from "react-router-dom";
import Select from 'react-select'
import moment from 'moment-timezone'

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '100%',
        marginTop: '10px'
    }),
    menu: (provided, state) => ({
        ...provided,
        bottom: '100%',
        top: 'auto'
    }),
}

function Checkout() {

    let navigate = useNavigate()
    const dispatch = useDispatch()
    
    const cart = useSelector(state => state.cart);

    const [totalPrice, setTotalPrice] = useState(0)
    const [isOpenCalculateShipping, setIsOpenCalculateShipping] = useState(false)
    const [[isLoadingFetchProvince, provinceList], setProvinceList] = useState([])
    const [[isLoadingFetchCity, cityList], setCityList] = useState([])
    const [[isLoadingFetchSubdistrict, subdistrictList], setSubdistrictList] = useState([])
    const [isProvinceSelected, setIsProvinceSelected] = useState(null)
    const [isCitySelected, setIsCitySelected] = useState(null)
    const [isSubdistrictListSelected, setIsSubdistrictListSelected] = useState(null)
    const [[isLoadingFetchCourier, courierList], setCourierList] = useState([])
    const [courierSelected, setCourierSelected] = useState(null)
    const [totalWeight, setTotalWeight] = useState(null)
    const [formData, setFormData] = useState({
        name: '',
        postCode: '',
        phoneNumber: '',
        email: '',
        fullAddress: ''
    })
    const [loadingPayment, setLoadingPayment] = useState(false)


    useEffect(() => {
        if(!cart[0]) return;
        generateTotalPrice()
        generateTotalWeight()
    },[cart])

    useEffect(() => {
        if(provinceList && provinceList[0]) return;
        getProvinceList()
    },[])

    useEffect(() => {
        if(isProvinceSelected) {
            getCityList()
        }
    },[isProvinceSelected])

    useEffect(() => {
        if(isCitySelected) {
            getSubdistrictList()
        }
    },[isCitySelected])

    useEffect(() => {
        if(isSubdistrictListSelected) {
            getCostShipping()
        }
    },[isSubdistrictListSelected])

    function generateTotalPrice() {
        let newTotalPrice = 0
        cart.map(item => {
            newTotalPrice += (item.product.discount ? item.product.discount : item.product.price) * item.quantity
        })
        setTotalPrice(newTotalPrice)
    }

    function generateTotalWeight() {
        let newTotalWeight = 0
        cart.map(item => {
            newTotalWeight += (item.product.weight ? item.product.weight : 500) * item.quantity
        })
        setTotalWeight(newTotalWeight < 1 ? 1 : newTotalWeight)
    }

    function getProvinceList() {
        setProvinceList([true, []])
        axios.get('/getProvince')
        .then(({data}) => {
            let options = []
            data.rajaongkir.results.map(province => {
                options.push({
                    label: province.province,
                    value: province.province_id
                })
            })
            setProvinceList([false,options])
    })
        .catch(err => {
            setProvinceList([false,[]])
            toast.error('Get provinsi failed please refresh page')
        })
    }
    
    function getCityList() {
        setIsCitySelected(null)
        setIsSubdistrictListSelected(null)
        setCityList([true,[]])
        setCourierList([true,[]])
        axios.get(`/getCity?province=${isProvinceSelected.value}`)
        .then(({data}) => {
            let options = []
            data.rajaongkir.results.map(city => {
                options.push({
                    label: city.city_name,
                    value: city.city_id
                })
            })  
            setCityList([false,options])
        })
        .catch(err => {
            setCityList([false,[]])
            toast.error('Get city failed please refresh page')
        })
    }

    function getSubdistrictList() {
        setIsSubdistrictListSelected(null)
        setSubdistrictList([true,[]])
        setCourierList([true,[]])
        axios.get(`/getSubdistrict?city=${isCitySelected.value}`)
        .then(({data}) => {
            let options = []
            data.rajaongkir.results.map(sub => {
                options.push({
                    label: sub.subdistrict_name,
                    value: sub.subdistrict_id
                })
            })  
            setSubdistrictList([false,options])
    })
        .catch(err => {
            setSubdistrictList([false,[]])
            toast.error('Get city failed please refresh page')
        })
    }

    function getCostShipping() {
        setCourierList([true, []])
        axios.get(`/getCost?subdistrictId=${isSubdistrictListSelected.value}&&weight=${totalWeight}&&courier=jne:jnt:sicepat`)
        .then(({data}) => {
            let tempCourier = []
            data.rajaongkir.results.map(courier => {
                tempCourier.push(courier)
            })
            setCourierList([false, tempCourier])
        })
        .catch(err => {
            setCourierList([false, []])
            toast.error('Get shipping courier failed please refresh page')
        })
    }

    function handleChangeAmount(product, indexProduct, value) {
        let newCart = [...cart]
        if(value > product.product.stock){
            toast.warning(`last stock: ${product.product.stock}`)
            return;
        }
        newCart[indexProduct].quantity = value

        dispatch({
            type: 'UPDATE_CART',
            data: newCart
        })
        localStorage.setItem('cart', JSON.stringify(newCart))

    }

    function handleMinAmount(product, indexProduct, value) {
        let newCart = [...cart]
        if(newCart[indexProduct].quantity == 1){
            return;
        }
        newCart[indexProduct].quantity -= value

        dispatch({
            type: 'UPDATE_CART',
            data: newCart
        })
        localStorage.setItem('cart', JSON.stringify(newCart))

    }
    function handleAddAmount(product, indexProduct, value) {
        let newCart = [...cart]
        if(product.product.stock == product.quantity){
            toast.warning(`last stock: ${product.product.stock}`)
            return;
        }
        newCart[indexProduct].quantity += value

        dispatch({
            type: 'UPDATE_CART',
            data: newCart
        })
        localStorage.setItem('cart', JSON.stringify(newCart))
    }

    function handleDeleteItem(item){
        let newCart = cart.filter(el => el.product._id !== item.product._id)

        dispatch({
            type: 'UPDATE_CART',
            data: newCart
        })
        localStorage.setItem('cart', JSON.stringify(newCart))
    }

    function handleClickCourier(courierCode, courierCost){
        setCourierSelected({courierCode, courierCost})
    }

    function validationData(){
        let result = true
        if(!formData.name.length){
            result = false
        }else if(!formData.fullAddress.length){
            result = false
        }else if(!formData.phoneNumber.length){
            result = false
        }else if(!formData.email.length){
            result = false
        }else if(!formData.postcode.length){
            result = false
        }
        return result;
    }

    function handlePayment(){
        if(!validationData()){
            toast.warning('Lengkapi semua data')
            return;
        }
        if(loadingPayment){
            return;
        }
        setLoadingPayment(true)
        var transactionDetail = {
            address: {}
        }
        let today = new Date()
        let getIndoDate = moment.tz(today, "Asia/Jakarta");
        let dateIndo = getIndoDate.format()

        transactionDetail.created_at = dateIndo
        transactionDetail.status = 'online'
        transactionDetail.cart = cart
        transactionDetail.shippingPrice = courierSelected.courierCost.cost[0].value
        transactionDetail.shippingName = courierSelected.courierCode
        transactionDetail.shippingService = courierSelected.courierCost.service
        transactionDetail.shippingDescription = courierSelected.courierCost.description
        transactionDetail.totalPrice = totalPrice + courierSelected.courierCost.cost[0].value
        transactionDetail.soldFrom = JSON.parse(localStorage.getItem('marketplaceInfo'))
        transactionDetail.note = formData.note
        transactionDetail.address.email = formData.email
        transactionDetail.address.province = isProvinceSelected.label
        transactionDetail.address.city = isCitySelected.label
        transactionDetail.address.kecamatan = isSubdistrictListSelected.label
        transactionDetail.address.firstName = formData.name
        transactionDetail.address.fullAddress = formData.fullAddress
        transactionDetail.address.phoneNumber = formData.phoneNumber
        transactionDetail.address.postcode = formData.postcode
        

        let tempGuest = {
            _id: 'G-'+moment(transactionDetail.created_at).format('MMMM Do YYYY, h:mm:ss a'),
        }
        transactionDetail.user = tempGuest
        // return;
        var transactionId = null
        var userId = null
        let tempCart = []
        transactionDetail.cart.map(item => {
            tempCart.push({
                'id': item.product._id,
                'price': (item.product.discount ? item.product.discount : item.product.price),
                'quantity': item.quantity,
                'name': `${item.product.name} - ${item.product.color.label} - ${item.product.size.label}`
            })
        })
        tempCart.push({
            'id': 'shipping-1',
            'price': transactionDetail.shippingPrice,
            'quantity': 1,
            'name': 'Ongkir'
        })
        axios.post('/transaction', transactionDetail)
        .then(({data}) => {
            transactionId = data._id
            userId = data.user._id
            return axios.post('/paymentCharge', {
                id: data._id,
                totalPrice: data.totalPrice,
                firstName: data.address.firstName,
                lastName: '',
                email: data.address.email,
                phoneNumber: data.address.phoneNumber,
                fullAddress: data.address.fullAddress,
                shippingPrice: data.shippingPrice,
                shippingName: data.shippingName,
                shippingService: data.shippingService,
                city: data.address.city,
                postcode: data.address.postcode,
                note: data.address.notes,
                user: data.user,
                items: tempCart,
            })
        })
        .then(({data}) => {
            {/**
                indomart
                payment_type: cstore
            */}
            setLoadingPayment(false)
            window.snap.pay(data.token, {
                onSuccess: function(result){
                    updateTransactionStatus(transactionId, result)
                },
                onPending: function(result){
                    /* You may add your own implementation here */
                    updateTransactionStatus(transactionId, result)
                    // alert("wating your payment!"); console.log(result);
                },
                onError: function(result){
                /* You may add your own implementation here */
                    deleteTransaction(transactionId, userId)
                    alert("payment failed!"); console.log(result);
                },
                onClose: function(){
                /* You may add your own implementation here */
                    deleteTransaction(transactionId, userId)
                    alert('you closed the popup without finishing the payment');
                }
            })
        })
        .catch(err => {
            setLoadingPayment(false)
            if(err && err.response){
                toast.error(`${err.response.data.message}`)
            }else {
                toast.error('Transaksi failed. PLease try again')
            }
            deleteTransaction(transactionId, userId)
        })
    }

    function updateTransactionStatus(transactionId, result) {
        let paymentMethodTemp = ''
        let paymentNumberTemp = ''
        if(result.payment_type === 'bank_transfer'){
            if(result.va_numbers){
                //handling BCA, Mandiri 
                paymentNumberTemp = result.va_numbers ? result.va_numbers[0].va_number : 'Silahkan Tanya Customer Service'
                paymentMethodTemp = 'Bank Transfer ' + result.va_numbers[0].bank.toUpperCase()
            }else if(result.permata_va_number){
                //handling Permata Bank
                paymentNumberTemp = result.permata_va_number ? result.permata_va_number : 'Silahkan Tanya Customer Service'
                paymentMethodTemp = 'Bank Transfer Permata'
            }
        }else if(result.payment_type === "echannel"){
            if(result.bill_key){
                paymentNumberTemp = result.bill_key ? result.bill_key : 'Silahkan Tanya Customer Service'
            }
            paymentMethodTemp =  'Bank Transfer Mandiri'
        }else if(result.payment_type === 'gopay'){
            paymentNumberTemp = 'QR-Code'
            paymentMethodTemp = 'GOPAY'
        }else if(result.payment_type === 'qris'){
            paymentNumberTemp = 'QR-Code'
            paymentMethodTemp = 'QRIS Shopee Pay'
        }else if(result.payment_type === 'cstore'){
            paymentNumberTemp = result.payment_code ? result.payment_code : 'Silahkan Tanya Customer Service'
            paymentMethodTemp = 'INDOMART/ALFAMART'
        }
        axios.put(`/transaction/${transactionId}`, {
            paymentMethod: paymentMethodTemp,
            paymentNumber: paymentNumberTemp,
        })
        localStorage.removeItem('cart')
        dispatch({
            type: 'UPDATE_CART',
            data: []
        })
        dispatch({
            type: 'UPDATE_TRANSACTION',
            data: {
                address: {
                    city: '',
                },
            }
        })
        dispatch({
            type: 'UPDATE_TRANSACTION_STATUS',
            data: {
                transactionId: transactionId,
                totalPrice: result.gross_amount,
                paymentMethod: paymentMethodTemp,
                paymentNumber: paymentNumberTemp,
                time: result.transaction_time
            }
        })
        navigate('/completeOrder')
    }

    function deleteTransaction(transactionId, userId){
        axios.delete(`/transaction?transactionId=${transactionId}&userId=${userId}` )
        .then(({data}) => {
            
        })
        .catch(err => {
            toast.error('Server error. PLease contact our support')
        })
    }


    return(
        <div className="lg:mt-[100px] md:mt-[96px] mt-[79px] md:px-10 lg:px-52 px-5 pb-8">
            <div className="pt-10 pb-20 flex justify-center gap-x-6 text-xs md:text-lg">
                <p className="relative after:absolute after:right-[-15px] after:top-0 after:content-['>'] after:ml-2 after:text-sm after:text-gray-500 after:flex after:items-center">SHOPPING CART</p>
                <p className="relative after:absolute after:right-[-15px] after:top-0 after:content-['>'] after:ml-2 after:text-sm after:text-gray-500 after:flex after:items-center">CHECKOUT DETAILS</p>
                <p>ORDER COMPLETE</p>
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-4/6 w-full">
                    <div className="uppercase flex border-b-4 border-b-gray pb-2">
                        <p className="flex-[3] text-sm font-semibold text-gray-600">product</p>
                        <p className="flex-1 text-sm font-semibold text-gray-600 hidden md:block">price</p>
                        <p className="flex-1 text-sm font-semibold text-gray-600 hidden md:block">quantity</p>
                        <p className="flex-1 text-sm font-semibold text-gray-600 hidden md:block">subtotal</p>
                    </div>
                    <div className="mt-5 border-b-2 border-b-gray pb-2">
                        {
                            cart.map((item, indexItem) => {
                                return (
                                    <div className="flex mb-2 flex md:flex-row flex-col">
                                        <div className="flex-[3] flex items-center gap-x-1">
                                            <button className="border-2 border-gray-400 text-gray-400 rounded-full h-5 w-5 text-xs" onClick={() => handleDeleteItem(item)}>X</button>
                                            <img className="md:max-w-[90px] max-w-[50px] md:max-h-32 max-h-24" src={`${process.env.REACT_APP_BASE_URL}/images/${item.product.image}`}/>
                                            <div className="hidden md:block">
                                                <p className="text-xs text-gray-500 max-w-[200px] text-ellipsis inline-block">{item.product.name}</p>
                                                <p className="text-slate-400 inline-block text-xs">{item.product.color.label}-{item.product.size.label}</p>
                                            </div>
                                            <div className="md:hidden flex flex-col ml-5">
                                                <p className="text-l text-gray-500 max-w-[200px] text-ellipsis">{item.product.name}</p>
                                                <p className="text-slate-400 text-sm">{item.product.color.label}-{item.product.size.label}</p>
                                                <p className="font-bold text-xs">Rp{convertNumber((item.product.discount ? item.product.discount : item.product.price))}</p>
                                            </div>
                                        </div>
                                        <div className="flex-1 items-center hidden md:flex">
                                            <p className="font-bold text-xs">Rp{convertNumber(item.product.discount ? item.product.discount : item.product.price)}</p>
                                        </div>
                                        <div className="flex-1 flex items-center mt-4 md:mt-0">
                                            <button className="border-[1px] flex justify-center items-center border-slate-400 text-slate-900 h-6 md:h-6 w-6 py-2 px-1" onClick={(e)=> handleMinAmount(item, indexItem, 1)}>-</button>
                                            <input className="w-8 text-center border-[1px] border-slate-400 text-slate-900 h-6 md:h-6 py-2 px-1 w-[40px]" placeholder="1" type="number" min="1" inputMode="numeric" value={item.quantity} onChange={(e)=> handleChangeAmount(item, indexItem, (e.target.value === '' || e.target.value == 0) ? 1 : Number(e.target.value))}/>
                                            <button className="border-[1px] flex justify-center items-center border-slate-400 text-slate-900 h-6 md:h-6 w-6 py-2 px-1" onClick={(e)=> handleAddAmount(item, indexItem, 1)}>+</button>
                                            <p className="font-bold text-xs md:hidden ml-3">Rp{convertNumber((item.product.discount ? item.product.discount : item.product.price) * item.quantity)}</p>
                                        </div>
                                        <div className="flex-1 flex items-center hidden md:flex">
                                            <p className="font-bold text-xs">Rp{convertNumber((item.product.discount ? item.product.discount : item.product.price) * item.quantity)}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button className="uppercase py-2 px-4 border-2 border-gray-900 mt-5 text-xs font-semibold" onClick={() => navigate('/')}>continue shopping</button>
                    <div className="mt-10">
                        <p className="uppercase text-2xl">billing & shipping</p>
                        <p className="text-sm my-2 font-semibold"> Nama Lengkap </p>
                        <input className="border-[1px] w-full px-[3px]" value={formData.name} onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                        <p className="text-sm mb-2 mt-5 font-semibold"> Alamat Lengkap </p>
                        <textarea rows="5" className="border-[1px] w-full px-[3px]" value={formData.fullAddress} onChange={(e) => setFormData({...formData, fullAddress: e.target.value})}/>
                        <p className="text-sm mb-2 mt-5 font-semibold"> Provinsi </p>
                        {
                            isLoadingFetchProvince && <span>Load province ...</span>
                        }
                        <Select
                            placeholder="provinsi"
                            styles={customStyles}
                            options={provinceList}
                            onChange={(value) => setIsProvinceSelected(value)}
                            value={isProvinceSelected ? {label: isProvinceSelected.label, value: isProvinceSelected.value} : null}
                        />
                        <p className="text-sm mb-2 mt-5 font-semibold"> Kota </p>
                        {
                            isLoadingFetchCity && <span>Load city ...</span>
                        }
                        <Select
                            placeholder="kota"
                            styles={customStyles}
                            options={cityList}
                            isDisabled={isLoadingFetchCity}
                            onChange={(value) => setIsCitySelected(value)}
                            value={isCitySelected ? {label: isCitySelected.label, value: isCitySelected.value} : null}
                        />
                        <p className="text-sm mb-2 mt-5 font-semibold"> Kecamatan </p>
                        {
                            isLoadingFetchSubdistrict && <span>Load subdisctrict ...</span>
                        }
                        <Select
                            placeholder="kecamatan"
                            styles={customStyles}
                            options={subdistrictList}
                            isDisabled={isLoadingFetchSubdistrict}
                            onChange={(value) => setIsSubdistrictListSelected(value)}
                            value={isSubdistrictListSelected ? {label: isSubdistrictListSelected.label, value: isSubdistrictListSelected.value} : null}
                        />
                        <p className="text-sm mb-2 mt-5 font-semibold"> Post Code </p>
                        <input className="border-[1px] w-full px-[3px]" value={formData.postcode} onChange={(e) => setFormData({...formData, postcode: e.target.value})}/>
                        <p className="text-sm mb-2 mt-5 font-semibold"> No. HP /Whatsapp </p>
                        <input className="border-[1px] w-full px-[3px]" type="number" value={formData.phoneNumber} onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}/>
                        <p className="text-sm mb-2 mt-5 font-semibold"> Email </p>
                        <input className="border-[1px] w-full px-[3px]" type="email" value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})}/>
                        <p className="text-sm mb-2 mt-5 font-semibold"> Note </p>
                        <input className="border-[1px] w-full px-[3px]" value={formData.note} onChange={(e) => setFormData({...formData, note: e.target.value})}/>
                    </div>
                </div>
                <div className="md:w-4/12 w-full border-2 border-black p-5 md:ml-5 mt-4 md:mt-0 h-fit">
                    <p className="uppercase font-bold text-sm">your order</p>
                    <div className="flex justify-between text-xs mt-5 font-semibold border-b-2 pb-2">
                        <p className="uppercase">product</p>
                        <p className="uppercase">subtotal</p>
                    </div>
                    <div className="flex flex-col border-b-2 pb-2">
                        {
                            cart.map((item, indexItem) => {
                                return (
                                    <div className="flex justify-between text-[10px] mt-5">
                                        <p className="">{item.product.name} * {item.quantity}</p>
                                        <p className="font-semibold">Rp{convertNumber((item.product.discount ? item.product.discount : item.product.price) * item.quantity)}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex justify-between text-xs mt-5 font-semibold border-b-2 pb-2">
                        <p className="font-semibold ">Subtotal</p>
                        <p className="font-semibold">Rp{convertNumber(totalPrice)}</p>
                    </div>
                    <div>
                        {
                            isLoadingFetchCourier ?
                                <span>Load shipping ...</span>
                            :
                                <div className="flex flex-col">
                                    {
                                        courierList && courierList.map(courier => {
                                            return (
                                                <div className="mt-2 border-b-2 pb-2">
                                                    <b>{courier.code.toUpperCase()}</b>
                                                    {
                                                        courier.costs.map(cost => {
                                                            return(
                                                                <div 
                                                                    className={`mt-2 cursor-pointer bg-gray-200 p-2 ${(courierSelected && courierSelected.courierCode === courier.code && courierSelected.courierCost.service === cost.service && 'bg-gray-300 border-l-2 border-l-black')}`}
                                                                    onClick={()=> handleClickCourier(courier.code, cost)}
                                                                >
                                                                    <p className="text-sm"><b>{cost.service}</b>({cost.description})</p> 
                                                                    <p className="inline-block">Rp{cost.cost[0].value}</p> <p className="inline-block text-xs">({cost.cost[0].etd.length ? `${cost.cost[0].etd} hari` : ' -' } )</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                    <div className="flex justify-between text-xs mt-5 font-semibold border-b-2 pb-2">
                        <p className="uppercase">grand total</p>
                        <p className="uppercase">{totalPrice + (courierSelected ? courierSelected.courierCost.cost[0].value : 0)}</p>
                    </div>
                    {
                        (courierSelected && isCitySelected && isSubdistrictListSelected) ? <button className="uppercase py-2 px-4 border-2 border-gray-900 mt-5 text-xs font-semibold bg-yellow-200" onClick={handlePayment}>payment</button> : <></>
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default Checkout;