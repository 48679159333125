
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './views/Home'
import ProductList from './views/ProductList'
import ProductDetail from './views/ProductDetail'
import Checkout from './views/Checkout'
import CompleteOrder from './views/CompleteOrder'
import { Provider } from 'react-redux';
import store from './store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useEffect, useState } from 'react';

function App() {

  useEffect(()=>{
        const midtransScriptUrl = 'https://app.midtrans.com/snap/snap.js'; 
        // const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js'; 
        const myMidtransClientKey = 'Mid-client-uDdOS7405HQnjqzv'; 
        // const myMidtransClientKey = 'SB-Mid-client-BSdOb1jKVd7Xse0-'; 
        
        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);
        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
  },[])

  return (
    <Provider store={store}>
      <Router>
        <Header/>
        <div className="min-h-[86vh]">
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/product/:idProduct" element={<ProductDetail/>}/>
            <Route exact path="/product/:subProduct/:idProduct" element={<ProductDetail/>}/>
            <Route exact path="/tag/:subProduct" element={<ProductList/>}/>
            <Route exact path="/category/:subProduct" element={<ProductList/>}/>
            <Route exact path="/search/:subProduct" element={<ProductList/>}/>
            <Route exact path="/checkout" element={<Checkout/>}/>
            <Route exact path="/completeOrder" element={<CompleteOrder/>}/>
          </Routes>
        </div>
        <Footer/>
      </Router>
        <ToastContainer 
          position="top-right"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick          
        />
    </Provider>
  );
}

export default App;
