import Select from 'react-select'
import {useState, useEffect, useRef} from 'react'
import {
    useParams,
    useNavigate,
    useLocation
  } from "react-router-dom";
import axios from 'axios'
import {toast} from 'react-toastify'
import { useSelector, useDispatch } from "react-redux";
import convertNumber from '../../helpers/convertnumber'
import InfiniteScroll from 'react-infinite-scroller';
import ProductSkeleton from '../../components/LoadingSkeleton/productSkeleton';

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '160px',
        fontSize: '10px'
    }),
}

function ProductList() {

    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { subProduct } = useParams();

    const categoryList = useSelector(state => state.categoryList);

    const [productList, setProductList] = useState([])
    const [hasMoreData, setHasMoreData] = useState(true)
    const [productListSortAsc, setProductListSortAsc] = useState(null)
    const [productListSortDesc, setProductListSortDesc] = useState(null)
    const [page, setPage] = useState(0)


    var scrollParentRef = useRef(null)

    useEffect(() => {
        setProductListSortDesc(null)
        setProductListSortAsc(null)
        setHasMoreData(true)
        fetchProduct(0)
    },[location.pathname, categoryList])

    const filterProduct = (products)=>{
        let productFilter = {}
        products.map(product => {
            if(!productFilter[product.name]){
                productFilter[product.name] = product
            }else if(product.gallery.length > productFilter[product.name].gallery.length){
                productFilter[product.name] = product
            }
        })
        let resultProduct = []
        Object.keys(productFilter).map(product => {
            resultProduct.push(productFilter[product])
        })
        setProductList(resultProduct)
    }

    const fetchProduct = (page) => {
        if(!categoryList[0]) return;
        let param = subProduct
        let path = location.pathname.split('/')[1]
        if(path == 'tag'){

            axios.get(`/productbyTag?tag=${param}&page=${page}`)
            .then(({data}) => {
                if(page == 0){
                    // setProductList(data)
                    filterProduct(data)
                    if(data.length === 0){
                        setHasMoreData(false)
                    }
                }else if(data.length > 0){
                    let newProductList = [...productList].concat(data)
                    // setProductList(newProductList)
                    filterProduct(newProductList)
                    
                }
                if(data.length == 0) {
                    setHasMoreData(false)
                }
                setPage(page + data.length)
                modalSidebar()
            })
            .catch(err => {
              toast.error('server error')
              modalSidebar()
            })
  
        }else if(path == 'category'){
            let category = categoryList.filter(item => (item.label).toLowerCase() === param.toLowerCase() )
            if(category[0]){
                axios.post(`/productbyCategory?page=${page}`, category[0])
                .then(({data}) => {
                setPage(page + data.length)
                  if(page == 0){
                    // setProductList(data)
                    filterProduct(data)
                    if(data.length === 0){
                      setHasMoreData(false)
                    }
                  }else if(data.length > 0){
                    let newProductList = [...productList].concat(data)
                    // setProductList(newProductList)
                    filterProduct(newProductList)
                }
                if(data.length == 0) {
                    setHasMoreData(false)
                }
                modalSidebar()
            })
            .catch(err => {
                toast.error('server error')
                modalSidebar()
            })
            }else {
                setProductList([])
                toast.error('No product match in category')
                modalSidebar()
            }
        }else if(path == 'search'){
            axios.get(`/productbySearch?search=${param}`)
            .then(({data}) => {
                setPage(page + data.length)
                // setProductList(data)
                filterProduct(data)
                setHasMoreData(false)
            })
            .catch(err => {
                toast.error('search product failed')
            })
        }
    }

    const modalSidebar = () => {
        dispatch({
            type: 'SET_OPEN_CLOSE_SIDEBAR',
            data: false
        })
    }

    const handleClickCard = (productData) => {
        // if(isDetailProduct) return;
        navigate(`/product/${subProduct}/${productData._id}`)
    }
    
    const handleChangeSort = (value)=>{
        if(value.value === 'dsc'){
            setProductListSortDesc(null)
            setProductListSortAsc(productList.sort((a,b) => {
                return (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0)
            }));
        }else {
            setProductListSortAsc(null)
            setProductListSortDesc(productList.sort((a,b) => {
              return (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0)
            }));
        }
    }

    return(
        <div className="lg:mt-[100px] md:mt-[96px] mt-[79px] md:px-10 lg:px-52 lg:py-5 px-5 ">
            <div className="py-5 flex justify-between">
                <div className="hidden md:block">
                    <p className="text-[10px]">Shop / Best Seller</p>
                    <div>
                        <p className="font-semibold text-gray-600 text-sm">FILTER</p>
                    </div>
                </div>
                <div className="flex gap-x-2 items-center">
                    <p className="text-xs">Showing all {productList.length} results</p>
                    <div className="">
                        <Select 
                            placeholder="Sort price"
                            styles={customStyles}
                            onChange={handleChangeSort}
                            options={[
                                {
                                    value: 'asc',
                                    label: 'Sort by price: high to low',
                                },
                                {
                                    value: 'dsc',
                                    label: 'Sort by price: low to high',
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div>
                <InfiniteScroll
                className="flex flex-wrap md:gap-x-4 gap-x-1 justify-around"
                    initialLoad={false}
                    loadMore={() => fetchProduct(page)}
                    hasMore={hasMoreData}
                    getScrollParent={() => scrollParentRef}
                    loader={(
                        <div className="flex">
                        <ProductSkeleton/>
                        <ProductSkeleton/>
                        <ProductSkeleton/>
                        <ProductSkeleton/>
                        </div>
                    )}
                >
                    {
                        productListSortAsc ?
                            productListSortAsc.map(product => {
                                return (
                                    <div onClick={() => handleClickCard(product)} key={product._id} className="md:w-1/6 w-2/5 p-2 relative cursor-pointer hover:shadow-md hover:bottom-1 flex flex-col">
                                        <img className="h-52 w-52 object-cover object-center" src={`${process.env.REACT_APP_BASE_URL}/images/${product.image}`}/>
                                        <p className="text-xs text-slate-500">{product.name}</p>
                                        <p className="text-sm font-bold text-slate-500">{product.color.label} - {product.size.label}</p>
                                        <p className="text-sm font-bold">Rp {convertNumber(product.price)}</p>
                                        {
                                            product.discount ? 
                                                <strike className="text-sm font-bold">Rp {convertNumber(product.price)}</strike>
                                            :
                                                <p className="text-sm font-bold">Rp {convertNumber(product.price)}</p>
                                        }
                                        {
                                            product.discount ? <p className="text-sm font-bold text-red-500">Rp {convertNumber(product.discount)}</p> :<></>
                                        }
                                        {
                                            product.discount ? (
                                                <p className="text-sm bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center absolute top-3 left-0">{Math.round(((product.price - product.discount)/product.price) * 100)}%</p>
                                            ) : <></>
                                        }
                                    </div>
                                )
                            })
                        :
                        productListSortDesc ? 
                            productListSortDesc.map(product => {
                                return (
                                    <div onClick={() => handleClickCard(product)} key={product._id} className="md:w-1/6 w-2/5 p-2 relative cursor-pointer hover:shadow-md hover:bottom-1 flex flex-col">
                                        <img className="h-52 w-52 object-cover object-center" src={`${process.env.REACT_APP_BASE_URL}/images/${product.image}`}/>
                                        <p className="text-xs text-slate-500">{product.name}</p>
                                        <p className="text-sm font-bold text-slate-500">{product.color.label} - {product.size.label}</p>
                                        <p className="text-sm font-bold">Rp {convertNumber(product.price)}</p>
                                        {
                                            product.discount ? 
                                                <strike className="text-sm font-bold">Rp {convertNumber(product.price)}</strike>
                                            :
                                                <p className="text-sm font-bold">Rp {convertNumber(product.price)}</p>
                                        }
                                        {
                                            product.discount ? <p className="text-sm font-bold text-red-500">Rp {convertNumber(product.discount)}</p> :<></>
                                        }
                                        {
                                            product.discount ? (
                                                <p className="text-sm bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center absolute top-3 left-0">{Math.round(((product.price - product.discount)/product.price) * 100)}%</p>
                                            ) : <></>
                                        }
                                    </div>
                                )
                            })
                        :
                        productList.map(product => {
                            return (
                                <div onClick={() => handleClickCard(product)} key={product._id} className="md:w-1/6 w-2/5 p-2 relative cursor-pointer hover:shadow-md hover:bottom-1 flex flex-col">
                                    <img className="md:h-52 md:w-52 h-40 w-40 object-contain object-center" src={`${process.env.REACT_APP_BASE_URL}/images/${product.image}`}/>
                                    <p className="text-xs font-bold text-slate-500">{product.name}</p>
                                    <p className="text-xs text-slate-500">{product.color.label} - {product.size.label}</p>
                                    {
                                        product.discount ? 
                                            <strike className="text-sm font-bold">Rp {convertNumber(product.price)}</strike>
                                        :
                                            <p className="text-sm font-bold">Rp {convertNumber(product.price)}</p>
                                    }
                                    {
                                        product.discount ? <p className="text-sm font-bold text-red-500">Rp {convertNumber(product.discount)}</p> :<></>
                                    }
                                    {
                                        product.discount ? (
                                            <p className="text-sm bg-red-500 text-white rounded-full w-10 h-10 flex items-center justify-center absolute top-3 left-0">{Math.round(((product.price - product.discount)/product.price) * 100)}%</p>
                                        ) : <></>
                                    }
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default ProductList;