import Select from 'react-select'
import {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from "react-redux";
import {
    useParams,
} from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios'
import convertNumber from '../../helpers/convertnumber'
import ReactImageMagnify from 'react-image-magnify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        width: '160px',
        fontSize: '10px'
    }),
}

function ProductDetail() {

    const { idProduct } = useParams();
    const dispatch = useDispatch()
    const refScroll = useRef(null)

    const cart = useSelector(state => state.cart);

    const [detailProduct, setDetailProduct] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isDropdownActive, setIsDropdownActive] = useState('description')
    const [imageList, setImageList] = useState([])
    const [imageShowCount, setImageShowCount] = useState(0)
    const [amount, setAmount] = useState(1)
    const [simillarProductList, setSimillarProductList] = useState([])
    const [colorList, setColorList] = useState([])
    const [sizeList, setSizeList] = useState([])
    const [selectColor, setSelectColor] = useState(null)
    const [selectSize, setSelectSize] = useState(null)
    const [emptyStock, setEmptyStock] = useState(false)
    const [isMobileView, setIsMobileView] = useState({ matches: window.matchMedia("(max-width: 768px)").matches })
    const [isTabView, setIsTabView] = useState({ matches: window.matchMedia("(max-width: 1024px)").matches })
    const [isShowPreviewImgPopup, setIsShowPreviewImgPopup] = useState(false)


    useEffect(() =>{
        const handler = e => setIsMobileView({matches: e.matches});
        window.matchMedia("(max-width: 768px)").addEventListener('change', handler);

        const handlerTab = e => setIsTabView({matches: e.matches});
        window.matchMedia("(max-width: 1024px)").addEventListener('change', handlerTab);

        var product = null
        axios.get(`/detailProduct/${idProduct}`)
        .then(({data}) =>{
            product = data
            setDetailProduct(data)
            setIsLoading(false)
            data.gallery.unshift(data.image)
            setImageList(data.gallery)
            return axios.get(`/productbySearch?search=${data.name}`)
        })
        .then(({data}) => {
            setSelectColor(product.color.label)
            setSelectSize(product.size.label)
            let colors = []
            let sizes = []
            data.map(item => {
                if(colors.findIndex(el => el === item.color.label) === -1){
                    colors.push(item.color.label)
                }
                if(sizes.findIndex(el => el === item.size.label) === -1){
                    sizes.push(item.size.label)
                }
            })
            setColorList(colors.sort())
            setSizeList(sizes.sort())
            setSimillarProductList(data)
        })
        .catch(err => {
            setIsLoading(false)
            toast.error('server error')
        })
    },[])

    useEffect(() => {
        if(selectColor && selectSize && simillarProductList.length){
            var isEmpty = true
            simillarProductList.map(product => {
                if(product.color.label === selectColor && product.size.label === selectSize){
                    if(!product.isUnshiftOnce){
                        product.isUnshiftOnce = true
                        product.gallery.unshift(product.image)
                    }
                    setDetailProduct(product)
                    setImageList(product.gallery)
                    isEmpty = false
                }
            })
            if(isEmpty) {
                setEmptyStock(true)
                toast.warning('Stok kosong')
            }
            else setEmptyStock(false)
        }
    },[selectColor, selectSize])

    function NewLineText(props) {

        const text = props.text;
        if(text[0]){
            return text.split('\n').map(str => <p>{str}</p>);
        }else {
            return <p className="text-grey">Tidak ada deskripsi</p>
        }
    }

    function handleClickDropdown(value) {
        if(isDropdownActive === value){
            setIsDropdownActive(null)
            return;
        }
        setIsDropdownActive(value)
    }

    function handleChangeAmount(e) {
        setAmount(e.target.value == '' ? e.target.value : Number(e.target.value))
    }

    function handleClickAddToCart() {
        if(emptyStock){
            toast.warning('Stok kosong')
            return
        }
        if(amount <= 0){
            toast.warning('Please add quantity')
            return;
        }
        let newCart = [...cart]
        let findProduct = newCart.findIndex(el => el.product._id === detailProduct._id)
        if(findProduct == -1){
            newCart.push({
                quantity: amount,
                product: detailProduct
            })
        }else {
            newCart[findProduct].quantity += amount
        }
        setAmount(1)
        dispatch({
            type: 'ADD_PRODUCT_TO_CART',
            data: newCart
        })   
        localStorage.setItem('cart', JSON.stringify(newCart))
        toast.success(`${detailProduct.name} add to cart`)   
    }

    return(
        <div className="lg:mt-[100px] md:mt-[96px] mt-[79px] md:px-10 lg:px-52 lg:py-5 px-5">
            <div className="flex py-10 flex-col lg:flex-row">
                <div className="p-2 object-contain bg-white flex justify-center lg:justify-start relative">
                    <ReactImageMagnify imageStyle={{objectFit: 'contain'}} enlargedImageContainerClassName="bg-enlarge-img" enlargedImageStyle={{objectFit: 'contain'}} {...{
                            smallImage: {
                                alt: detailProduct.name,
                                isFluidWidth: false,
                                src: `${process.env.REACT_APP_BASE_URL}/images/${imageList[imageShowCount]}`,
                                width: isMobileView.matches ? 200 : 500,
                                height: isMobileView.matches ? 200 : 500,
                            },
                            largeImage: {
                                src: `${process.env.REACT_APP_BASE_URL}/images/${imageList[imageShowCount]}`,
                                width: isMobileView.matches ? 200 : 800,
                                height: isMobileView.matches ? 200 : 800,
                            },
                            enlargedImageContainerStyle:{
                                zIndex: 2
                            },
                            shouldUsePositiveSpaceLens: true,
                            enlargedImagePosition: (isMobileView.matches || isTabView.matches) && 'over',
                    }}/>
                    <p className="absolute bottom-0 right-0 bg-slate-200 rounded-full text-slate-500 p-1 text-xs">{imageShowCount+1}/{imageList.length}</p>
                </div>
                <div className="flex max-w-md overflow-scroll gap-x-2 blok lg:hidden my-4 border-[1px] p-2 border-slate-200">
                    {
                        imageList.map((image, indexImg) => {
                            return (
                                <img 
                                    key={indexImg} 
                                    onClick={() => {
                                        setImageShowCount(indexImg);
                                        setIsShowPreviewImgPopup(true)
                                    }} 
                                    className="cursor-pointer object-contain h-16 w-16 cursor-pointer" src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}/>

                            )
                        })
                    }
                </div>
                {
                    isShowPreviewImgPopup ? 
                        <div className="fixed w-full h-full bg-[#272727e0] z-20 left-1/2 translate-x-[-50%] flex items-center mt-[-120px]">
                            <div 
                                className="absolute left-0 cursor-pointer h-full bottom-0 flex items-center"
                                onClick={() => {}}
                            >
                                <FontAwesomeIcon 
                                    icon={faArrowCircleLeft} 
                                    className="text-4xl text-white select-none z-20"
                                    onClick={() => imageShowCount >= 1 && setImageShowCount(imageShowCount - 1)}
                                    />
                            </div>
                            <div className="relative m-auto flex flex-col items-center">
                                <p className="text-white bg-slate-500 text-center p-px mb-4 px-1" onClick={() => setIsShowPreviewImgPopup(false)}>x close</p>
                                <img className="object-contain h-full max-h-96 w-full" src={`${process.env.REACT_APP_BASE_URL}/images/${imageList[imageShowCount]}`}/>
                            </div>
                            <div 
                                className="absolute right-0 cursor-pointer h-full bottom-0 flex items-center z-10"
                                onClick={() => {}}
                                >
                                <FontAwesomeIcon 
                                    icon={faArrowCircleRight} 
                                    className="text-4xl text-white select-none z-20"
                                    onClick={() => imageShowCount < imageList.length-1 && setImageShowCount(imageShowCount + 1)}
                                />
                            </div>
                        </div>
                    : <></>
                }
                {/* <img className="max-w-lg" src={`${process.env.REACT_APP_BASE_URL}/images/${detailProduct.image}`}/> */}
                <div className="lg:ml-8">
                    <p className="text-2xl font-bold text-slate-700 mb-5">{detailProduct.name}</p>
                    <div className="h-1 w-8 bg-slate-300"/>
                    {
                        detailProduct.discount ? 
                            <div className="flex items-center">
                                <strike className="text-red-600 text-sm">Rp {convertNumber(detailProduct.price)}</strike> &nbsp;
                                <p className="text-2xl my-5 font-bold">Rp {convertNumber(detailProduct.discount)}</p>
                                <div className="ml-4 text-sm text-white uppercase bg-red-600 rounded-sm p-1">{Math.round(((detailProduct.price - detailProduct.discount)/ detailProduct.price) * 100)}% OFF</div>
                            </div>
                        :
                            <p className="text-2xl my-5 font-bold">Rp {convertNumber(detailProduct.price)}</p>
                    }
                    <div className="flex items-center mb-5">
                        <p className="text-xs font-bold mr-2">WARNA</p>
                        {
                            colorList.map(color => {
                                return (
                                    <button onClick={() => setSelectColor(color)} className={`border-[1px] rounded-[4px] border-slate-400 p-1 text-sm mx-1 ${selectColor === color ? 'bg-yellow-300' : 'bg-white'}`}>{color}</button>
                                )
                            })
                        }
                    </div>
                    <div className="flex items-center mb-5">
                        <p className="text-xs font-bold mr-2">SIZE</p>
                        {
                            sizeList.map(size => {
                                return (
                                    <button key={size} onClick={() => setSelectSize(size)} className={`border-[1px] rounded-[4px] border-slate-400 p-1 text-sm mx-1 ${selectSize === size ? 'bg-yellow-300' : 'bg-white'}`}>{size}</button>
                                )
                            })
                        }
                    </div>
                    <p>Stok: {emptyStock ? 0 : detailProduct.stock}</p>
                    <div className="h-[1px] w-8 bg-slate-300 mb-5"/>
                    <div className="flex">
                        <button className="border-[1px] flex justify-center items-center border-slate-400 text-slate-900 w-6 py-2 px-1" onClick={() => amount > 0 && setAmount(amount - 1)}>-</button>
                        <input className="w-10 text-center  border-[1px] border-slate-400 text-slate-900 py-2 px-1" placeholder="1" type="number" min="0" inputMode="numeric" onChange={handleChangeAmount} value={amount}/>
                        <button className="border-[1px] flex justify-center items-center border-slate-400 text-slate-900 w-6 py-2 px-1" onClick={() =>  setAmount(amount + 1)}>+</button>
                        <button className="ml-5 bg-yellow-400 rounded-[4px] text-white font-bold p-2 border-[1px] border-yellow-400 cursor-pointer" onClick={handleClickAddToCart}>ADD TO CART</button>
                    </div>
                    <div className="fixed right-[20px] bottom-[20px]">
                        <a className="mt-5 flex bg-green-600 rounded-full pr-5 cursor-pointer text-white items-center" href="https://api.whatsapp.com/send/?phone=6281210001027&text=Hi+Kak%2C+aku+ada+kendala+dipesananku%2C+bisa+tolong+dibantu" target="_blank">
                            <img className="w-16 h-1w-16 rounded-full mr-2" src="/logo.png"/>
                            <div>
                                <p className="text-xs flex"><p className="rounded-3xl">Chat</p></p>
                                <p className="text-sm font-bold">WHATSAPP</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="relative max-w-lg">
                <div 
                    className="absolute left-0 cursor-pointer bg-stone-100 opacity-50 h-full bottom-0 flex items-center hidden md:flex"
                    onClick={() => refScroll.current.scrollLeft -= 100}
                >
                    <FontAwesomeIcon 
                        icon={faArrowCircleLeft} 
                        className="text-2xl"
                    />
                </div>
                <div className="flex max-w-lg overflow-scroll gap-x-2 hidden lg:flex scroll-smooth" ref={refScroll}>
                    {
                        imageList.map((image, indexImg) => {
                            return (
                                <img key={indexImg} onClick={() => setImageShowCount(indexImg)} className="cursor-pointer h-32 w-32 cursor-pointer" src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}/>

                            )
                        })
                    }
                </div>
                <div 
                    className="absolute right-0 cursor-pointer bg-stone-100 opacity-50 h-full bottom-0 flex items-center z-10 hidden md:flex"
                    onClick={() => refScroll.current.scrollLeft += 100}
                >
                    <FontAwesomeIcon 
                        icon={faArrowCircleRight} 
                        className="text-2xl"
                    />
                </div>
            </div>
            <div className="mt-10 border-t-2 pb-32">
                <p onClick={() => handleClickDropdown('description')} className={`text-xs cursor-pointer font-bold mb-5 inline-block mr-5 ${isDropdownActive === 'description' && 'border-t-2 border-black pt-2'}`}>DESCRIPTION</p>
                <p onClick={() => handleClickDropdown('information')} className={`text-xs cursor-pointer font-bold mb-5 inline-block ${isDropdownActive === 'information' && 'border-t-2 border-black pt-2'}`}>ADDITIONAL INFORMATION</p>
                {
                    isDropdownActive === 'description' ? 
                        <div className="bg-slate-200 p-2">
                            <NewLineText text={detailProduct.description ? detailProduct.description : ''} />
                        </div>
                    :
                    <>
                        <p className="text-slate-500">Dimensi</p>
                        <div className="flex flex-col">
                            <div className="flex border-b-2 border-slate-400 py-3">
                                <p className="text-sm mr-28 font-bold">WEIGHT</p>
                                <p className="text-xs">{detailProduct.weight}gr</p>
                            </div>
                            <div className="flex border-b-2 border-slate-400 py-3">
                                <p className="text-sm mr-28 font-bold">WARNA</p>
                                <p className="text-xs lowercase">{detailProduct.color.value}</p>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className="my-10">
                {
                    (detailProduct.url && detailProduct.url.length) && (
                        <iframe src={detailProduct.url}
                            frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                            width="100%"
                            height="400"
                        />
                    )
                }
            </div>
        </div>
    )
}

export default ProductDetail;