import React from 'react';
// import './styles.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const ProductSkeleton = () => {
  return (
    <div>
      <div className="md:w-1/6 w-2/5 p-2 relative cursor-pointer md:mr-3 mr-1">
        <Skeleton width={210} height={208}/>
        <Skeleton width={210} height={16}/>
        <Skeleton width={210} height={18}/>
      </div>
    </div>
  )
}

export default ProductSkeleton;