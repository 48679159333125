
import {useState, useEffect} from 'react'
import axios from 'axios'
import {
    useNavigate,
    Link,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa } from '@fortawesome/free-solid-svg-icons'
import {toast} from 'react-toastify'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function Home() {

    let navigate = useNavigate()

    const banner1 = `'${process.env.REACT_APP_BASE_URL}/ecommerce/banner1.jpg'`
    const [isMobileView, setIsMobileView] = useState({ matches: window.matchMedia("(max-width: 768px)").matches })
    const [eventBanners, setEventBanners] = useState([])

    const [dashboardImage, setDashboardImage] = useState({
        mainBannerDekstop: '',
        mainBannerMobile: '',
        mainBannerHeaderText: '',
        mainBannerBodyText: '',
        customOrderHeaderText: '',
        customOrderBodyText: '',
        socmedHeaderText: '',
        socmedBodyText: '',
        event1: '',
        event2: '',
        event3: '',
        event4: '',
        event5: '',
        categoryA: '',
        categoryB: '',
        categoryC: '',
        categoryD: '',
        categoryE: '',
        categoryF: '',
        activityRiding: '',
        activityTravel: '',
        activityCasual: '',
        popular: '',
        newProductA: '',
        newProductB: '',
        newProductC: '',
        newProductAID: '',
        newProductBID: '',
        newProductCID: '',
        customOrder: '',
        socmed: '',
        newProductText1 : '',
        newProductText2 : '',
        newProductText3 : '',
        newProductColorBlack1 : false,
        newProductColorBlack2 : false,
        newProductColorBlack3 : false,
    })

    useEffect(() =>{
        const handler = e => setIsMobileView({matches: e.matches});
        window.matchMedia("(max-width: 768px)").addEventListener('change', handler);

        axios.get('bannerImage')
        .then(({data}) =>{
            let eventsBannerTemp = []
            
            if(data[0].event1.length){
                eventsBannerTemp.push(`${process.env.REACT_APP_BASE_URL}/images/${data[0].event1}`)
            }
            if(data[0].event2.length){
                eventsBannerTemp.push(`${process.env.REACT_APP_BASE_URL}/images/${data[0].event2}`)
            }
            if(data[0].event3.length){
                eventsBannerTemp.push(`${process.env.REACT_APP_BASE_URL}/images/${data[0].event3}`)
            }
            if(data[0].event4.length){
                eventsBannerTemp.push(`${process.env.REACT_APP_BASE_URL}/images/${data[0].event4}`)
            }
            if(data[0].event5.length){
                eventsBannerTemp.push(`${process.env.REACT_APP_BASE_URL}/images/${data[0].event5}`)
            }
            setEventBanners(eventsBannerTemp)
            
            let temp = {...dashboardImage}
            let flag = false
            Object.keys(data[0]).map(image => {
                if(dashboardImage[image] !== undefined && data[0][image].length ){
                    flag = true
                    if(image.includes('Text')){
                        temp[image] = `${data[0][image]}`
                    }else if(image == 'newProductAID' || image == 'newProductBID' || image == 'newProductCID'){
                        temp[image] = `${data[0][image]}`
                    }else{
                        temp[image] = `${process.env.REACT_APP_BASE_URL}/images/${data[0][image]}`
                    }
                }
                if(image == 'newProductColorBlack1' || image == 'newProductColorBlack2' || image == 'newProductColorBlack3'){
                    flag = true
                    temp[image] = data[0][image]
                }
            })
            if(flag){ 
                console.log(temp)
                setDashboardImage(temp)
            }
        })
        .catch(err => {
            toast.error('server error')
        })
    },[])

    const movePageByCategory = (value) => {
        navigate(`/category/${value}`)
    }

    const movePageByTag = (value) => {
        navigate(`/tag/${value}`)
    }

    const movePageByID = (value) => {
        navigate(`/product/${value}`)
    }

    return (
        <div className="lg:mt-[100px] md:mt-[96px] mt-[79px]">
            <div className={`h-[668px] 2xl:h-[1180px] bg-center bg-no-repeat bg-cover flex justify-center lg:items-end items-center`} style={{backgroundImage: `url(${ isMobileView.matches ? dashboardImage.mainBannerMobile : dashboardImage.mainBannerDekstop})`}}> 
                <div className="flex flex-col items-center lg:w-7/12 w-9/12 lg:mb-[20px]">
                    <p className="font-bold text-3xl text-center my-3 text-yellow-600">{dashboardImage.mainBannerHeaderText}</p>
                    <p className="text-sm text-center my-3 text-white">{dashboardImage.mainBannerBodyText}</p>
                </div>
            </div>
            <p className="text-center text-3xl my-3 text-red-600">EVENT</p>
            {
                eventBanners.length ? (
                    <div>
                        <Carousel
                        infiniteLoop
                        autoPlay
                        showThumbs={false}
                        showStatus={false}
                        >
                            {
                                eventBanners.map(image => {
                                    return (
                                        <div>
                                            <img className="md:h-[70vh] h-[50vh] md:h-[70vh] h-[50vh] w-full object-center object-no-repeat object-contain md:object-cover" src={`${image}`} />
                                        </div>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                ) : <></>
            }
            {/* <div className="md:h-[70vh] h-[50vh] w-full bg-center bg-no-repeat bg-contain md:bg-cover" style={{backgroundImage: `url(${dashboardImage.event})`}}/> */}
            <p className="text-center text-3xl my-3">CATEGORY</p>
            <div className="flex flex-wrap">
                <div onClick={() => movePageByCategory('waist bag')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryA})`}}>
                </div>
                <div onClick={() => movePageByCategory('backpack')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryB})`}}>
                </div>
                <div onClick={() => movePageByCategory('jacket')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryC})`}}>
                </div>
                <div onClick={() => movePageByCategory('pants')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryD})`}}>
                </div>
                <div onClick={() => movePageByCategory('slippers')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryE})`}}>
                </div>
                <div onClick={() => movePageByCategory('accessories')} className="h-[300px] xl:h-[400px] cursor-pointer border-[1px] border-white md:w-4/12 w-2/4 bg-center bg-no-repeat bg-cover flex justify-center items-center" style={{backgroundImage: `url(${dashboardImage.categoryF})`}}>
                </div>
            </div>
            <p className="text-center text-3xl my-3">ACTIVITY</p>
            <div className="flex flex-wrap">
                <div className="h-[300px] flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 w-full bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.activityRiding})`}}>
                    <div className="text-white flex flex-col items-center">
                        <p className="text-2xl font-semibold text-center tracking-widest">RIDING</p>
                        <button onClick={() => movePageByTag('riding')} className="border-2 border-white cursor-pointer text-xs py-1 px-3 rounded-md">SHOP NOW</button>
                    </div>
                </div>
                <div className="h-[300px] flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 w-full bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.activityTravel})`}}>
                    <div className="text-white flex flex-col items-center">
                        <p className="text-2xl font-semibold text-center tracking-widest"> TRAVELER</p>
                        <button onClick={() => movePageByTag('travel')} className="border-2 border-white cursor-pointer text-xs py-1 px-3 rounded-md">SHOP NOW</button>
                    </div>
                </div>
                <div className="h-[300px] flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 w-full bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.activityCasual})`}}>
                    <div className="text-white flex flex-col items-center">
                        <p className="text-2xl font-semibold text-center tracking-widest">CASUAL</p>
                        <button onClick={() => movePageByTag('casual')} className="border-2 border-white cursor-pointer text-xs py-1 px-3 rounded-md">SHOP NOW</button>
                    </div>
                </div>
            </div>
            <p className="text-center text-3xl my-3">MOST POPULAR</p>
            <div className="flex items-center justify-center h-[50vh] bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.popular})`}}>
                <div className="flex flex-col items-center text-white w-5/12">
                    <button onClick={() => movePageByTag('best seller')} className="border-2 border-white cursor-pointer text-xs py-1 px-3 my-3 rounded-md">SHOP NOW</button>
                </div>
            </div>
            <p className="text-center text-3xl my-3">NEW PRODUCTS</p>
            <div className="flex flex-wrap">
                <div className="md:h-[480px] h-[300px] w-full flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 bg-left bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.newProductA})`}}>
                    <div className={`${!dashboardImage.newProductColorBlack1 ? 'text-white' : 'text-black' } flex flex-col items-center`}>
                        <p className="text-2xl font-semibold text-center tracking-widest">{dashboardImage.newProductText1}</p>
                        <button onClick={() => movePageByID(dashboardImage.newProductAID)} className={`border-2 ${!dashboardImage.newProductColorBlack1 ? 'border-white' : 'border-black' } cursor-pointer text-xs py-1 px-3 rounded-md`}>SHOP NOW</button>
                    </div>
                </div>
                <div className="md:h-[480px] h-[300px] w-full flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.newProductB})`}}>
                    <div className={`${!dashboardImage.newProductColorBlack2 ? 'text-white' : 'text-black' } flex flex-col items-center`}>
                        <p className="text-2xl font-semibold text-center tracking-widest">{dashboardImage.newProductText2}</p>
                        <button onClick={() => movePageByID(dashboardImage.newProductBID)} className={`border-2 ${!dashboardImage.newProductColorBlack2 ? 'border-white' : 'border-black' } cursor-pointer text-xs py-1 px-3 rounded-md`}>SHOP NOW</button>
                    </div>
                </div>
                <div className="md:h-[480px] h-[300px] w-full flex justify-center items-end p-10 border-[1px] border-white md:w-4/12 bg-right bg-no-repeat bg-cover" style={{backgroundImage: `url(${dashboardImage.newProductC})`}}>
                    <div className={`${!dashboardImage.newProductColorBlack3 ? 'text-white' : 'text-black' } flex flex-col items-center`}>
                        <p className="text-2xl font-semibold text-center tracking-widest">{dashboardImage.newProductText3}</p>
                        <button onClick={() => movePageByID(dashboardImage.newProductCID)} className={`border-2 ${!dashboardImage.newProductColorBlack3 ? 'border-white' : 'border-black' } cursor-pointer text-xs py-1 px-3 rounded-md`}>SHOP NOW</button>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center h-[80vh] bg-center bg-no-repeat bg-cover mt-6" style={{backgroundImage: `url(${dashboardImage.customOrder})`}}>
                <div className="flex flex-col items-center text-white w-5/12">
                    <p className="font-bold text-3xl text-center my-3">{dashboardImage.customOrderHeaderText}</p>
                    <p className="text-sm text-center my-3">{dashboardImage.customOrderBodyText}</p>
                    <button onClick={() => movePageByCategory('custom order')} className="border-2 border-white cursor-pointer text-xs py-1 px-3 my-3 rounded-md">
                        SHOP NOW
                    </button>
                </div>
            </div>
            <div className="flex items-end justify-center h-[80vh] bg-center bg-no-repeat bg-cover mt-6" style={{backgroundImage: `url(${dashboardImage.socmed})`}}>
                <div className="flex flex-col items-center text-white w-9/12 mb-[80px] md:mb-[100px] lg:mb-[75px] xl:mb-[50px] 2xl:mb-[80px] 3xl:mb-[120px] 4xl:mb-[40px]">
                    <p className="font-bold text-3xl text-center my-3">{dashboardImage.socmedHeaderText}</p>
                    <p className="text-sm text-center my-3">{dashboardImage.socmedBodyText}</p>
                    <div className="flex gap-x-8 mt-8">
                        <a href="https://youtube.com/channel/UCb4dZuyvL5E_HNkj0G9ducQ" target="_blank"> <i class="fab fa-youtube text-white text-4xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl 4xl:text-7xl"></i> </a>
                        <a href="https://instagram.com/evernext.id?igshid=YmMyMTA2M2Y=" target="_blank"> <i class="fab fa-instagram text-white text-4xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl 4xl:text-7xl"></i> </a>
                        <a href="https://t.me/evernextteam" target="_blank"> <i class="fab fa-telegram text-white text-4xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl 4xl:text-7xl"></i> </a>
                        <a href="https://vt.tiktok.com/ZSdsQAmu3/" target="_blank"> <i class="fab fa-tiktok text-white text-4xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl 4xl:text-7xl"></i> </a>
                        <a href="https://api.whatsapp.com/send/?phone=6281210001027&text=Hi+Kak%2C+aku+ada+kendala+dipesananku%2C+bisa+tolong+dibantu" target="_blank"> <i class="fab fa-whatsapp text-white text-4xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl 4xl:text-7xl"></i> </a>
                    </div>
                </div>

                
            </div>
        </div>
    )
}


export default Home;